<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row align="stretch">
      <v-col class="col-12 col-md-4 col-lg-4">
        <OverviewCard :loading="preloading" :data="data" v-if="!preloading" />
      </v-col>

      <v-col class="col-12 col-md-8 col-lg-8">
        <MapCard :loading="preloading" :data="data" v-if="!preloading" />
      </v-col>

      <v-col class="col-12">
        <NotesCard
          :loading="preloading"
          :data="data.notes"
          :pitchId="pitchId"
          v-if="!preloading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import OverviewCard from "@/components/pitches/OverviewCard.vue";
import MapCard from "@/components/pitches/MapCard.vue";
import NotesCard from "@/components/pitches/NotesCard.vue";

export default {
  name: "Pitch",

  components: {
    PageHeader,
    OverviewCard,
    MapCard,
    NotesCard,
  },

  data() {
    return {
      preloading: false,
      pageTitle: "Pitch",
      pageDescription:
        "Hier kannst du den laufenden Pitch einsehen und bearbeiten.",
      pitchId: this.$route.params.id,
      data: [],
    };
  },

  created: function() {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      // GET PITCH
      this.preloading = true;
      const response = await this.getRequest("pitches/" + this.pitchId);
      this.preloading = false;
      this.data = response.data.data;
    },
  },

  mixins: [apiRequest],
};
</script>
